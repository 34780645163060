.container {
    margin-top: 50px;
}
body {
    background-color: #caced427;
}
button {
    margin-top: 30px;
    background-color: #5271d5;
    border: 0;
    padding: 10px 20px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    color: #ffffff;
    border-color: #f89140;
    display: inline-block;
    background-color: #f89140;

    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    width: 100%;
    margin-top: 30px;
    background-color: #5271d5;
    border: 0;
    padding: 10px 20px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.prieveiwImg_sec {
    width: 150px;
    /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    border-radius: 10px;
    padding: 10px; */
    margin-top: 10px;
}
.upload_design input[type="file"] {
    display: none;
}
.slick-list {
    width: 400px;  
}
.slick-prev:before, .slick-next:before {
    color: #161515 !important;
}
ul.slick-dots {
    display: none !important;
}

.inner_Pages div{
    margin: 0 auto;
}
ul.outer_frontSec {
    display: flex;
    list-style: none;
    /* margin-bottom: 40px; */
    align-items: center;
    justify-content: center;
    margin: 5px 0;
}
.outer_fronbackSec {
    border: 1px solid #c5cace;
}
ul.outer_frontSec li{
   margin: 0 40px;
}
.canvas-container {
    margin: 0 auto;
    box-shadow: rgb(75 89 109 / 25%) 0px 0px 10px 0px;
    
}
.slick-prev, .slick-next {
    top: 40% !important;
}
.uploade_mainSection .slick-list {
    width: 900px;
}
.upload_design {
    display: flex;
}
.upload.sec_outerDiv {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 400px;
    height: 300px;
    background-color: white;
    box-shadow: rgb(75 89 109 / 25%) 0px 0px 10px 0px;
    margin: 0px auto;

}
button.get-started-btn.scrollto.mobile_btn {
    margin: 0 0 0 10px;
}
.uploadDesign_header .btn-primary {
    background-color: #fff;
    border-color: initial;
    color: #fff;
    margin: 0;
}
.uploadDesign_header .btn-group {
    border: 1px solid #d9e2eb;
    border-radius: 5px;
    text-align: center;
    max-width: 300px;
}
.reviewouter_sec {
    border: 1px solid rgb(223, 227, 231);
    box-shadow: rgb(75 89 109 / 25%) 0px 0px 10px 0px;
    width: 350px;
    height: 250px;
    position: relative;
}
.review_middleSec {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    margin-top: 40px;
}
.review_middleSec img {
    width: 100%;
}
.edit_reviewImg {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0;
    right: 0;
    background-color: #fff;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin: 10px;
}
.uploade_mainSection {
    margin-top: 40px;
}